/**
 * This middleware redirects logged-in users away from pages like login, register etc
 */

export default defineNuxtRouteMiddleware(() => {
  const auth = useAuth()

  if (auth.loggedIn) {
    if (auth.me?.isOnboarded) {
      return navigateTo('/playbook')
    }
    else {
      return navigateTo(useRuntimeConfig().public.featureFlags.onboarding.path)
    }
  }
})
